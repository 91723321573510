.app {
}

nav a {
  padding: 15px;
}

.navbar-collapse {
  padding-right: 15px;
}
